// @import "react-bootstraptable";
@import '~noty/src/noty.scss';
@import '~noty/src/themes/mint.scss';
@import 'zendeskchat.scss';

.css-yk16xz-control {
  max-width: 300px;
}
.multi-select {
  &.css-2b097c-container {
    .css-yk16xz-control {
      max-width: 100%;
    }
  }
}
