/* wifi */
.wifi-symbol {
  display: none;
}
.wifi-symbol [foo],
.wifi-symbol {
  /* display: block; */
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 150px;
  height: 150px;
  margin-top: -187.5px;
  -ms-transform: rotate(-45deg) translate(-100px);
  -moz-transform: rotate(-45deg) translate(-100px);
  -o-transform: rotate(-45deg) translate(-100px);
  -webkit-transform: rotate(-45deg) translate(-100px);
  transform: rotate(-45deg) translate(-100px);
}
.wifi-symbol .wifi-circle {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21.4285714286px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: #00aeef;
  border-style: solid;
  border-width: 1em 1em 0 0;
  -webkit-border-radius: 0 100% 0 0;
  border-radius: 0 100% 0 0;
  opacity: 0;
  -o-animation: wifianimation 3s infinite;
  -moz-animation: wifianimation 3s infinite;
  -webkit-animation: wifianimation 3s infinite;
  animation: wifianimation 3s infinite;
}
.wifi-symbol .wifi-circle.first {
  -o-animation-delay: 800ms;
  -moz-animation-delay: 800ms;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.wifi-symbol .wifi-circle.second {
  width: 5em;
  height: 5em;
  -o-animation-delay: 400ms;
  -moz-animation-delay: 400ms;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}
.wifi-symbol .wifi-circle.third {
  width: 3em;
  height: 3em;
}
.wifi-symbol .wifi-circle.fourth {
  width: 1em;
  height: 1em;
  opacity: 1;
  background-color: #ffffcc;
  -o-animation: none;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
}
.wifi-symbol .mark {
  width: 6px;
  height: 50px;
  background: #f75555;
  position: absolute;
  z-index: 1;
  bottom: 11px;
  left: 32px;
  transform: rotate(45deg);
  border-radius: 2px;
  animation: blink 3s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}
.wifi-symbol .mark:before {
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #f75555;
  position: absolute;
  bottom: -16px;
  left: -1px;
  display: block;
}
.wifi-text {
  display: inline-block;
  position: absolute;
  top: 70%;
  left: 50%;
  margin-left: -170px;
  color: #767777;
  font-family: Arial, Helvetica, sans-serif;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

@-o-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  6% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@-moz-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  6% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
@-webkit-keyframes wifianimation {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 1;
  }
  6% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
.hide {
  display: none;
}
