// Zendesk Chat Customization
iframe#launcher {
    left: initial !important;
    top: 2px !important;
    right: 60px !important;
    margin: 6px 15px !important;
}

iframe#webWidget {
    left: initial !important;
    right: 0 !important;
}

//student status barchart css here
.barchart {
    &-box {
        position: relative;
    }
    &-refrence {
        display: flex;
        width: 100%;
        justify-content: center;
        font-size: 12px;
        position: absolute;
        top: 30px;
        opacity: 0;
        .denotion-1 {
            width: 130px;
            margin-right: 10px;
        }
        .denotion-2 {
            width: 306px;
        }
        .denotion-3{
            width: 140px;
        }
        .denotion-4{
            width: 180px;
        }
        p {
            cursor: pointer;
            display: inline-block;
        }
    }
}